// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom CSS Loader
@import 'loader';


body {
    background-image: url('/img/background-white.jpg');
    background-color: #CCC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: 'Arial Bold', Helvetica, sans-serif;
    font-weight: bold !important;
}

.form-check {
    page-break-inside: avoid;
}

.form-check-label {
    padding-left: 4px;
}

.background-noise {
    background-image: url('/img/background-black.jpg');
    background-color: #333;
    color: white;
}

.half-height {
    min-height: 50vh !important;
}

.full-height {
    min-height: calc(100vh - 140px) !important;
}

.logo {
    text-align: center;
    vertical-align: middle;
    line-height: 1.25rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.5rem;
    background-image: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.logo > span {
    display: inline-block;
    animation-name: rotate;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: steps(2);
    transform: rotate(-10deg);
    text-shadow: 0.5px 0.5px 0.5px black,-0.5px 0.5px 0.5px black,0.5px -0.5px 0.5px black,-0.5px -0.5px 0.5px black;
    padding-right: 1px;
}

.logo-1 {
    color: orangered;
    animation-duration: 1s;
}

.logo-2 {
    color: orange;
    animation-duration: 0.953s;
    animation-delay: -0.5s;
}

.logo-3 {
    color: yellow;
    animation-duration: 0.823s;
}

.logo-4 {
    color: green;
    animation-duration: 0.877s;
    animation-delay: -0.5s;
}

.logo-5 {
    color: turquoise;
    animation-duration: 0.797s;
    animation-delay: -0.5s;
}

.logo-6 {
    color: skyblue;
    animation-duration: 0.991s;
}

.logo-7 {
    color: plum;
    animation-duration: 0.977s;
    animation-delay: -0.5s;
}

.logo-8 {
    color: violet;
    animation-duration: 0.709s;
}

@keyframes rotate {
    100% {
        transform: rotate(30deg);
    }
  }

